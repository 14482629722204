// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v4.24.4
// source: misc.proto

/* eslint-disable */
import { type DcHKlasseType, type DcHKonkurrenceArt, type KonkurrenceType } from "./dchEnums";
import { type Timestamp } from "./google/protobuf/timestamp";
import { type DcHCompetitionData } from "./shared";

export const protobufPackage = "DcHWebResProtobuf.misc";

export enum DcHFileImportStatus {
  None = 0,
  Imported = 1,
  PossibleImported = 2,
  UNRECOGNIZED = -1,
}

export interface ImportantInformationResponse {
  messages: string[];
}

export interface ApiResponse {
  statusText: string;
  statusCode: number;
  message: string;
}

export interface DcHLiveDataTokenResponse {
  dchLiveDataTokens: DcHLiveDataToken[];
}

export interface DcHLiveDataToken {
  rowId: number;
  dcHEventId: number;
  foreningId: number;
  forening: string;
  konkurrenceDato: Timestamp | undefined;
  konkurrenceDato2?: Timestamp | undefined;
  konkurrenceNavn: string;
  konkurrenceType: KonkurrenceType;
  konkurrenceArt: DcHKonkurrenceArt;
  guid: string;
  jsonData: string | undefined;
  liveText: string | undefined;
  active: boolean;
  lastUpdated: Timestamp | undefined;
}

export interface LogDataResponse {
  totalNoOfItems: number;
  logDatas: LogData[];
}

export interface LogData {
  createdOn?: Timestamp | undefined;
  level: string | undefined;
  message: string | undefined;
  logger: string | undefined;
  url: string | undefined;
  userName: string | undefined;
  exception: string | undefined;
  logFile: string | undefined;
  id: number;
  appName: string | undefined;
}

export interface DcHCompetitionDataResponse {
  competitionDatas: DcHCompetitionData[];
}

export interface DcHFileModelResponse {
  dchFileModels: DcHFileModel[];
}

export interface DcHFileModel {
  competitionData: DcHCompetitionData | undefined;
  guid: string;
  version: number;
  year: number;
  fileName: string;
  isValid: boolean;
  dchFileImportStatus: DcHFileImportStatus;
}

export interface DmQualificationPeriodResponse {
  periods: DmQualificationPeriod[];
}

export interface DmQualificationPeriod {
  rowId: number;
  dcHKlasseType: DcHKlasseType;
  startDate: Timestamp | undefined;
  endDate: Timestamp | undefined;
}

export interface BeregnerProgramResponse {
  beregnerPrograms: BeregnerProgram[];
}

export interface BeregnerProgram {
  name: string;
  url: string;
  version: string;
  lastUpdated: Timestamp | undefined;
}

<div class="pageHeader">
  <h1>Admin stamdata</h1>
</div>

<div class="pageBody">
  <mat-form-field>
    <input #searchBox id="searchBox" matInput type="text" (keyup)="search(searchBox.value)" required minlength="3" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="stamDatas" [editRowData]="dataRowDetails"></app-dch-data-table>
</div>

<ng-template #dataRowDetails let-data>
  <div class="adminStamDataDetailsContainer">
    <h3>Udtag til DM</h3>
    <div class="adminStamDataDetailsForm">
      <form (ngSubmit)="onFormSubmit(data)" [formGroup]="getStamDataFormGroup(data)">
        <div class="flex-container wrap">

          <app-dch-select [(selectedValue)]="data.dmUdtagAgility" selectLabel="Agility" [myFormControl]="udtagAgilityFormControl" [selectItems]="agilitySelectItems"></app-dch-select>
          <app-dch-select [(selectedValue)]="data.dmUdtagLydighed" selectLabel="Lydighed" [myFormControl]="udtagLydighedFormControl" [selectItems]="lydighedSelectItems"></app-dch-select>
          <app-dch-select [(selectedValue)]="data.dmUdtagRally" selectLabel="Rally" [myFormControl]="udtagRallyFormControl" [selectItems]="rallySelectItems"></app-dch-select>
          <app-dch-select [(selectedValue)]="data.udtagETur" selectLabel="E tur" [myFormControl]="udtagETurFormControl" [selectItems]="eturSelectItems"></app-dch-select>
          <app-dch-select [(selectedValue)]="data.dmUdtagNordisk" selectLabel="Nordisk" [myFormControl]="udtagNordiskFormControl" [selectItems]="nordiskSelectItems"></app-dch-select>
          <app-dch-select [(selectedValue)]="data.dmUdtagNoseWork" selectLabel="NoseWork" [myFormControl]="udtagNoseWorkFormControl" [selectItems]="noseWorkSelectItems"></app-dch-select>
          <app-dch-select [(selectedValue)]="data.dmUdtagIpg3" selectLabel="IPG3" [myFormControl]="udtagIpg3FormControl" [selectItems]="igp3SelectItems"></app-dch-select>
          <app-dch-select [(selectedValue)]="data.dmUdtagIgpFh" selectLabel="IGP FH" [myFormControl]="udtagIgpFhFormControl" [selectItems]="igpFhSelectItems"></app-dch-select>

          <mat-form-field appearance="fill" class="dchUdtagField">
            <mat-label>Note</mat-label>
            <input matInput type="text" [formControl]="ekvipageNoteFormControl">
          </mat-form-field>
        </div>

        <div class="saveStamDataButton">
          <button mat-raised-button color="primary" type="submit" [disabled]="disableSaveButton || (httpDataService.loading$ | async)"><mat-icon>save</mat-icon>Gem</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>



import { DmUdtagAgility, DmUdtagIgpFh, DmUdtagIpg3, DmUdtagLydighed, DmUdtagNordisk, DmUdtagNoseWork, DmUdtagRally, UdtagETur } from '../../../models.generated/dchEnums';
import { SelectItem } from '../../../models/select-item';

export class AdminStamDataHelpers {

  getAgilitySelectItems(): SelectItem<DmUdtagAgility>[] {
    return [
      new SelectItem('Auto', DmUdtagAgility.DmUdtagAgilityAuto),
      new SelectItem('Udtag ikke', DmUdtagAgility.DmUdtagAgilityIkke),
      new SelectItem('Udtag Small', DmUdtagAgility.InviteAsSmall),
      new SelectItem('Udtag Medium', DmUdtagAgility.InviteAsMedium),
      new SelectItem('Udtag Intermediate', DmUdtagAgility.InviteAsIntermediate),
      new SelectItem('Udtag Large', DmUdtagAgility.InviteAsLarge)
    ];
  }

  getIgp3SelectItems(): SelectItem<DmUdtagIpg3>[] {
    return [
      new SelectItem('Auto', DmUdtagIpg3.DmUdtagIpg3Auto),
      new SelectItem('Udtag ikke', DmUdtagIpg3.DmUdtagIpg3Ikke),
      new SelectItem('Udtag IGP3', DmUdtagIpg3.InviteAsIpg3)
    ];
  }

  getIgpFhSelectItems(): SelectItem<DmUdtagIgpFh>[] {
    return [
      new SelectItem('Auto', DmUdtagIgpFh.DmUdtagIgpFhAuto),
      new SelectItem('Udtag ikke', DmUdtagIgpFh.DmUdtagIgpFhIkke),
      new SelectItem('Udtag IgpFh', DmUdtagIgpFh.InviteAsIgpFh)
    ];
  }

  getLydighedSelectItems(): SelectItem<DmUdtagLydighed>[] {
    return [
      new SelectItem('Auto', DmUdtagLydighed.DmUdtagLydighedAuto),
      new SelectItem('Udtag ikke', DmUdtagLydighed.DmUdtagLydighedIkke),
      new SelectItem('Udtag C klassen', DmUdtagLydighed.InviteAsC),
      new SelectItem('Udtag B klassen', DmUdtagLydighed.InviteAsB),
      new SelectItem('Udtag A klassen', DmUdtagLydighed.InviteAsA),
      new SelectItem('Udtag E klassen', DmUdtagLydighed.InviteAsE)
    ];
  }

  getRallySelectItems(): SelectItem<DmUdtagRally>[] {
    return [
      new SelectItem('Auto', DmUdtagRally.DmUdtagRallyAuto),
      new SelectItem('Udtag ikke', DmUdtagRally.DmUdtagRallyIkke),
      new SelectItem('Udtag lille/veteran', DmUdtagRally.InviteAsLilleVeteran),
      new SelectItem('Udtag mellem/stor', DmUdtagRally.InviteAsMellemStor)
    ];
  }

  getEturSelectItems(): SelectItem<UdtagETur>[] {
    return [
      new SelectItem('Auto', UdtagETur.UdtagETurAuto),
      new SelectItem('Udtag ikke', UdtagETur.UdtagETurIkke),
      new SelectItem('Udtag E tur', UdtagETur.InviteAsETur)
    ];
  }

  getNordiskSelectItems(): SelectItem<DmUdtagNordisk>[] {
    return [
      new SelectItem('Auto', DmUdtagNordisk.DmUdtagNordiskAuto),
      new SelectItem('Udtag ikke', DmUdtagNordisk.DmUdtagNordiskIkke),
      new SelectItem('Udtag runder', DmUdtagNordisk.InviteAsRunder),
      new SelectItem('Udtag spor', DmUdtagNordisk.InviteAsSpor)
    ];
  }

  getNoseWorkSelectItems(): SelectItem<DmUdtagNoseWork>[] {
    return [
      new SelectItem('Auto', DmUdtagNoseWork.DmUdtagNoseWorkAuto),
      new SelectItem('Udtag ikke', DmUdtagNoseWork.DmUdtagNoseWorkIkke),
      new SelectItem('Udtag NoseWork', DmUdtagNoseWork.InviteAsNoseWork)
    ];
  }
}
